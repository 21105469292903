import { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";

import { ATTRIBUTES } from "../../../constants/attributes";
import { TASK_TYPE } from "../../../constants/task-types.js";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { TASK_ICONS } from "../../../constants/css-classes.js";

import { DEFAULT_RETRY_COUNT, DEFAULT_USER_INPUT_INVALID_VOICE_FILE, DEFAULT_USER_OPTION_INPUT_TIMEOUT } from "../../../config/config.js";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import VoicePromptAccordion from "../../common/voice-prompt-accordion/voice-prompt-accordion.js";
import PmivrLabel, { LABEL_SIZE } from "../../common/label/pmivr-label.js";
import { PmivrCheckbox } from "../../common/checkbox/pmivr-checkbox.js";
import { VoiceContext } from "../../../contexts/app-context";
import SpeechInput from "../speech-input/SpeechInput";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip.js";
import MultiVoiceFileOptionView from "../multiple-voice-file-option/MultiVoiceFileOptionView.js";
import SingleVoiceFileOption from "../single-voice-file-option/SingleVoiceFileOption.js";
import DynamicOptionPropertiesView from "../dynamic-option-task-properties/DynamicOptionPropertiesView.js";
import { useSelector } from "react-redux";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file.js";
import { VoicePrompt } from "../../../models/voice-file.js";

import AppUtil from "../../../util/app.util.js";

import ElementService from "../../../services/element.service.js";

//  constant for isOption attribute
const OPTION_VAR = {
  keyValueUserOption: "keyValueOpt:keyValueOptionVar",
  promptUserOption: "usrOpt:optionVar",
  dynamicUserOption: "dynamicOpt:dynamicOptionVar"
};
//  constant for retryCount attribute
const RETRY_VAR = {
  keyValueUserOption: "keyValueOpt:keyValueOptionRetryCount",
  promptUserOption: "usrOpt:optionRetryCount",
  dynamicUserOption: "dynamicOpt:dynamicOptionRetryCount"
};

const OptionUserTaskView = () => {
  const { element } = useContext(VoiceContext);

  /**
   * ui state object to update values on builder when user interacts
   * selectedTaskType: the task type selected by user (dynamic option, single voice file option etc.)
   * isSingleVoiceFileUserOption: boolean value that shows if the option is of single voice file type
   * isMultiVoiceFileUserOption: boolean value that shows if the option is of multi voice file type
   * isUserOptionsFromCollectionArray: boolean value that shows if the option is of dynamic option type
   * selectedTaskType: the task type selected by user (dynamic option, single voice file option etc.)
   * title: title of the task type
   * info: info message of the task type for info icon
   */
  const [uiState, setUiState] = useState({
    selectedTaskType: '', isSingleVoiceFileUserOption: false, isMultiVoiceFileUserOption: false,
    isUserOptionsFromCollectionArray: false, title: '', infoMessage: '', showOptionMenu: false
  });

  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // information for invalid voice file 
  const [invalidVoiceFileInfo, setInvalidVoiceFileInfo] = useState({});
  // information for call end voice file for invalid input
  const [callEndVoiceFileInfo, setCallEndVoiceFileInfo] = useState({});
  // information for call end voice file for no input
  // if no input is given then play the different voice file else if invalid input then play different file
  const [callEndOnNoInputVoiceFileInfo, setCallEndOnNoInputVoiceFileInfo] = useState({});
  // information for the no input voice file
  const [noInputVoiceFileInfo, setNoInputVoiceFileInfo] = useState({});

  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);
  const { languagesConfigured } = useSelector(state => state.client);

  // title for different user option types
  const TITLE = {
    PROMPT_USER_OPTION: "Single Voice File for All Options",
    DYNAMIC_USER_OPTION: "List Items As Options",
    KEY_VALUE_USER_OPTION: "Voice File for Every Option"
  };

  // User option types for selection
  const userOptionTypes = [
    { text: TITLE.PROMPT_USER_OPTION, value: TASK_TYPE.promptUserOption },
    { text: TITLE.KEY_VALUE_USER_OPTION, value: TASK_TYPE.keyValueUserOption },
    { text: TITLE.DYNAMIC_USER_OPTION, value: TASK_TYPE.dynamicUserOption }
  ];

  useEffect(() => {
    const init = async () => {
      // reading the value of allowInputDuringPrompt. On creation of new user option, this value will be undefined.
      const allowInputDuringPrompt = element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT);
      const skipSingleOptionConfigured = element.businessObject.get(ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED);
      const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES);
      if (!AppUtil.isValueValid(allowInputDuringPrompt)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT, true);
      }
      // check the regex validity if valid or not
      if (!AppUtil.isValueValid(skipSingleOptionConfigured)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED, true);
      }
      if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES, true);
      }
      const retryCount = element.businessObject.get(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)]);
      if (!retryCount) {
        updateOptionRetryCount(DEFAULT_RETRY_COUNT);
      }

      const inputTimeout = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT);
      if (!inputTimeout) {
        // if not defined, then set it to 3000 ms
        ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT, DEFAULT_USER_OPTION_INPUT_TIMEOUT);
      }

      const taskType = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.promptUserOption);
      updateUiState(taskType);
      setSelectedLanguage(languagesConfigured?.length && languagesConfigured?.includes("en") ? "en" : languagesConfigured[0]);
      // get the voice file informations
      getCallEndVoiceFileInfo();
      getInvalidVoiceFileInfo();
      getNoInputVoiceFileInfo();
      getCallEndOnNoInputVoiceFileInfo();
    }
    init();
  }, [element]);

  /**
   * Get the call end voice file info for option selection node
   */
  const getCallEndVoiceFileInfo = () => {
    let callEndVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_CALL_END_VOICE_FILE);
    callEndVoiceFileInfo = JSON.parse(callEndVoiceFileInfo);
    const tmpCallEndPromptInfo = {};
    if (callEndVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        tmpCallEndPromptInfo[lang] = callEndVoiceFileInfo[lang];
      });
    }
    setCallEndVoiceFileInfo(tmpCallEndPromptInfo);
  }

  /**
   * Get the invalid voice file info for option selection node
   */
  const getInvalidVoiceFileInfo = () => {
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
    const tmpInvalidVoiceFilePromptInfo = {};
    if (invalidVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        if (invalidVoiceFileInfo[lang]?.voiceFileType) {
          tmpInvalidVoiceFilePromptInfo[lang] = [new VoicePrompt(invalidVoiceFileInfo[lang])];
        } else {
          tmpInvalidVoiceFilePromptInfo[lang] = invalidVoiceFileInfo[lang];
        }
      });
    } else {
      languagesConfigured.forEach((lang) => {
        tmpInvalidVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: voiceFilePrefixObj[lang] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE, voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY })];
      });
    }
    setInvalidVoiceFileInfo(tmpInvalidVoiceFilePromptInfo);
  }

  /**
   * Get the no input voice file info for option selection node
   */
  const getNoInputVoiceFileInfo = () => {
    // get the no input voice file info list from element and set it in the state
    let noInputVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_NO_INPUT_VOICE_FILE);
    const tmpNoInputVoiceFileInfo = {};
    if (noInputVoiceFileInfo) {
      noInputVoiceFileInfo = JSON.parse(noInputVoiceFileInfo);
      languagesConfigured.forEach((lang) => {
        tmpNoInputVoiceFileInfo[lang] = noInputVoiceFileInfo[lang];
      });
    }
    setNoInputVoiceFileInfo(tmpNoInputVoiceFileInfo);
  }

  /**
   * Get the call end voice file info for option selection node on no input
   */
  const getCallEndOnNoInputVoiceFileInfo = () => {
    let callEndOnNoInputVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_CALL_END_ON_NO_INPUT_VOICE_FILE);
    callEndOnNoInputVoiceFileInfo = JSON.parse(callEndOnNoInputVoiceFileInfo);
    const tmpCallEndOnNoInputPromptInfo = {};
    if (callEndOnNoInputVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        tmpCallEndOnNoInputPromptInfo[lang] = callEndOnNoInputVoiceFileInfo[lang];
      });
    }
    setCallEndOnNoInputVoiceFileInfo(tmpCallEndOnNoInputPromptInfo);
  }

  /**
   * Update the state with the given task type info
   * @param {string} taskType selected task type
   */
  const updateUiState = (taskType) => {
    const detail = getTitleWithInfoMessage(taskType);
    setUiState({
      ...uiState, selectedTaskType: taskType,
      isSingleVoiceFileUserOption: taskType === TASK_TYPE.promptUserOption,
      isMultiVoiceFileUserOption: taskType === TASK_TYPE.keyValueUserOption,
      isUserOptionsFromCollectionArray: taskType === TASK_TYPE.dynamicUserOption,
      title: detail.title, infoMessage: detail.infoMessage, showOptionMenu: false
    });

  }

  /**
   * Getting the title with info message for the given task type
   * @param {string} taskType selected task type
   * @returns {{ title, infoMessage }} title along with it's the info message
   */
  const getTitleWithInfoMessage = (taskType) => {
    let title = '';
    let infoMessage = '';
    switch (taskType) {
      case TASK_TYPE.promptUserOption:
        title = TITLE.PROMPT_USER_OPTION;
        infoMessage = TOOLTIP.INFO.SINGLE_VOICE_FILE_USER_OPTION;
        break;
      case TASK_TYPE.dynamicUserOption:
        title = TITLE.DYNAMIC_USER_OPTION;
        infoMessage = TOOLTIP.INFO.COLLECTION_ARRAY_AS_USER_OPTIONS;
        break;
      case TASK_TYPE.keyValueUserOption:
        title = TITLE.KEY_VALUE_USER_OPTION;
        infoMessage = TOOLTIP.INFO.MULTIPLE_VOICE_FILE_USER_OPTION;
        break;
      default:
        break;
    }
    return { title, infoMessage };
  }

  // setting the properties as per change in selection of task type
  const setOptionEnableByTaskType = () => {
    switch (element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)) {
      case TASK_TYPE.promptUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, true);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, false);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, false);
        break;
      case TASK_TYPE.dynamicUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, false);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, true);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, false);
        break;
      case TASK_TYPE.keyValueUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, false);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, false);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, true);
        break;
      default:
        // do nothing
        break;
    }
  }

  // update element's attbributes
  const updateElementAttr = (key, value) => {
    ElementService.updateElement(element, key, value);
  }

  // update retry count of the task
  const updateOptionRetryCount = (optionRetryCount) => {
    const attr = RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)];
    updateElementAttr(attr, optionRetryCount?.toString());
  }

  /**
   * Updates the no input voice file list and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleNoInputVoiceFileUpdate = (updatedPromptsList, language) => {
    const updatedNoInputVoiceFileInfo = { ...noInputVoiceFileInfo, [language]: updatedPromptsList };
    setNoInputVoiceFileInfo(updatedNoInputVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_NO_INPUT_VOICE_FILE, JSON.stringify(updatedNoInputVoiceFileInfo));
  }

  /**
   * Handles the event of changing the selection of user option type.
   * Updates the uiState as well as flow attributes for the current element
   * @param {object} event current event
   */
  const handleUserOptionTypeChange = (event) => {
    // getting the task type, that was before change
    const oldOptionTaskType = uiState.selectedTaskType;
    let preOptionVar;
    let preRetryCount;
    // reading the values of retry count and option variable, before change
    switch (oldOptionTaskType) {
      case TASK_TYPE.promptUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.promptUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.promptUserOption);
        break;
      case TASK_TYPE.dynamicUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.dynamicUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.dynamicUserOption);
        break;
      case TASK_TYPE.keyValueUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.keyValueUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.keyValueUserOption);
        break;
      default:
        break;
    }

    // nullify the retry count for old task type (that was before change) in flow xml
    updateElementAttr(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)], MESSAGES.EMPTY_STRING);
    // nullify the option variable for old task type (that was before change) in flow xml
    updateElementAttr(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)], MESSAGES.EMPTY_STRING);

    // getting the new changed task type
    const selectedTaskType = event.target.value;
    // updates the flow attributes for the current element, as per new selection of task type
    switch (selectedTaskType) {
      case TASK_TYPE.promptUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.promptUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.promptUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.promptUserOption);
        // clearing the mapping of option and it's value, on change in drop down
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, null);
        break;
      case TASK_TYPE.dynamicUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.dynamicUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.dynamicUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.dynamicUserOption);
        break;
      case TASK_TYPE.keyValueUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.keyValueUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.keyValueUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.keyValueUserOption);
        // clearing the mapping of option and it's value, on change in drop down
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, null);
        break;
      default:
        break;
    }
    // populating flags (usrOpt:isOptionInput, dynamicOpt:isDynamicOption, keyValueOpt:isKeyValueOption, etc)
    // in flow xml as per new selection
    setOptionEnableByTaskType();
    // updating the ui state as change in task type
    updateUiState(selectedTaskType);
  }

  /**
   * updates the prompt list and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateCallEndPromptList = (updatedPromptsList, language) => {
    const updatedCallEndVoiceFileInfo = { ...callEndVoiceFileInfo, [language]: updatedPromptsList };
    setCallEndVoiceFileInfo(updatedCallEndVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_CALL_END_VOICE_FILE, JSON.stringify(updatedCallEndVoiceFileInfo));
  }

  /**
   * updates the prompt list of invalid voice file and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateInvalidPromptList = (updatedPromptsList, language) => {
    const updatedInvalidVoiceFileInfo = { ...invalidVoiceFileInfo, [language]: updatedPromptsList };
    setInvalidVoiceFileInfo(updatedInvalidVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, JSON.stringify(updatedInvalidVoiceFileInfo));
  }

  /**
   * updates the prompt list and also updates the element with the updated list for call end on no input voice file
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateCallEndOnNoInputPromptList = (updatedPromptsList, language) => {
    const updatedCallEndOnNoInputVoiceFileInfo = { ...callEndOnNoInputVoiceFileInfo, [language]: updatedPromptsList };
    setCallEndOnNoInputVoiceFileInfo(updatedCallEndOnNoInputVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_CALL_END_ON_NO_INPUT_VOICE_FILE, JSON.stringify(updatedCallEndOnNoInputVoiceFileInfo));
  }

  return (
    <>
      <VoiceContext.Provider
        value={{
          supportedLanguages: languagesConfigured, selectedLanguage: selectedLanguage,
          invalidVoiceFileInfo: invalidVoiceFileInfo, element: element
        }}>
        <div className="m-2 mt-3 pmivr-option-user">
          <Form.Group controlId="formBasicSelect">
            <PmivrLabel label="Select User Option Type" tooltip={TOOLTIP.INFO.SELECT_USER_OPTION_INPUT_TYPE} />
            <select className="pmivr-sub-title small mt-2 pmivr-select" as="select" value={uiState.selectedTaskType}
              onChange={(event) => handleUserOptionTypeChange(event)}>
              {
                userOptionTypes.map((userOptionType) => {
                  return (
                    <option className="pmivr-sub-title small" key={userOptionType.value} value={userOptionType.value}>
                      {userOptionType.text}
                    </option>
                  );
                })
              }
            </select>
          </Form.Group>
          {(uiState.title) && (
            <div className="mt-3">
              <PmivrLabel label={uiState.title} tooltip={uiState.infoMessage} size={LABEL_SIZE.MEDIUM} />
              <hr className="mt-1" />
            </div>
          )}

          <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
            <input className="form-check-input" type="checkbox" id="is-instant-response"
              checked={element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT) || false}
              onChange={(e) => {
                ElementService.updateElement(element, ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT, e.target.checked);
              }}
            />
            <label className="form-check-label">Allow Input During Prompt</label>
          </div>
          <PmivrTooltip message={TOOLTIP.INFO.IS_INSTANT_RESPONSE_USER_OPTION}>
            <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
          </PmivrTooltip>

          <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
            <input className="form-check-input" type="checkbox" id="is-skip-if-single-option"
              checked={element.businessObject.get(ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED) || false}
              onChange={(e) => {
                ElementService.updateElement(element, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED, e.target.checked);
              }}
            />
            <label className="form-check-label">Skip Options If Single Option Configured</label>
          </div>
          <PmivrTooltip message={TOOLTIP.INFO.SKIP_IF_SINGLE_USER_OPTION}>
            <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
          </PmivrTooltip>

          <PmivrCheckbox label={"End Call If Invalid Option Input After Retries"}
            value={element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES) || false}
            info={TOOLTIP.INFO.END_CALL_IF_INVALID_INPUT_AFTER_RETRY}
            onChange={(value) => {
              ElementService.updateElement(element, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES, value);
            }} />

          <div className="pmivr-clearfix pt-2">
            <div className="form-group mb-3">
              <PmivrLabel label="Input Timeout" tooltip={TOOLTIP.INFO.USER_OPTION_INPUT_TIMEOUT} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_TIMEOUT}>
                <input
                  id="optionInputTimeout" name="optionInputTimeout" type="number" className="form-control pmivr-input"
                  placeholder="Enter Timeout"
                  value={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT) || ""}
                  onChange={(event) => {
                    ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT, event.target.value);
                  }}
                />
              </PmivrOverlayTrigger>
            </div>
            <div className="form-group mb-3">
              <PmivrLabel label="Response Variable" tooltip={TOOLTIP.INFO.OPTION_VARIABLE} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.OPTION_VARIABLE}>
                <input id="optionVar" name="optionVar" className="form-control pmivr-input" placeholder="Enter Variable"
                  value={element.businessObject.get(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)])}
                  onChange={(event) => {
                    updateElementAttr(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)],
                      event.target.value);
                  }} />
              </PmivrOverlayTrigger>
            </div>
            <div className="form-group mb-3">
              <PmivrLabel label="Retry Count" tooltip={TOOLTIP.INFO.RETRY_COUNT} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.RETRY_COUNT}>
                <input id="optionRetryCount" name="optionRetryCount" className="form-control pmivr-input"
                  value={element.businessObject.get(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)])}
                  defaultValue={DEFAULT_RETRY_COUNT} onChange={(event) => { updateOptionRetryCount(event.target.value); }} />
              </PmivrOverlayTrigger>
            </div>

            <div className="m-1">
              {uiState.isSingleVoiceFileUserOption && (
                <SingleVoiceFileOption />
              )}
              {uiState.isMultiVoiceFileUserOption && (
                <MultiVoiceFileOptionView />
              )}
              {uiState.isUserOptionsFromCollectionArray && (
                <DynamicOptionPropertiesView />
              )}

              <VoicePromptAccordion title="No Input Voice File" voicePromptInfo={noInputVoiceFileInfo} onSave={handleNoInputVoiceFileUpdate} />

              {/* <Invalid option voice file with prompt list /> */}
              <VoicePromptAccordion title="Invalid Voice File" voicePromptInfo={invalidVoiceFileInfo} onSave={handleUpdateInvalidPromptList} />

              {/* <Call end voice file with prompt list /> */}
              <VoicePromptAccordion title="Call End Voice File On Invalid Input" voicePromptInfo={callEndVoiceFileInfo} onSave={handleUpdateCallEndPromptList} />

              {/* <Call end on no input voice file with prompt list /> */}
              <VoicePromptAccordion title="Call End Voice File On No Input" voicePromptInfo={callEndOnNoInputVoiceFileInfo} onSave={handleUpdateCallEndOnNoInputPromptList} />

              <SpeechInput noInputTimeout={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT)} />
            </div>
          </div>
        </div>
      </VoiceContext.Provider>
    </>
  );
}

export default OptionUserTaskView;