import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { Accordion } from "react-bootstrap";
import { debounce } from "lodash";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { ATTRIBUTES } from "../../../constants/attributes";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { TAB_LIST } from "../../../constants/element";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../common/label/pmivr-label";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";
import { TASK_ICONS } from "../../../constants/css-classes";
import { PmivrCheckbox } from "../../common/checkbox/pmivr-checkbox";
import VoicePromptAccordion from "../../common/voice-prompt-accordion/voice-prompt-accordion";

import { DEFAULT_RETRY_COUNT, DEFAULT_USER_INPUT_INVALID_VOICE_FILE } from "../../../config/config";
import { ACCORDION } from "../../../constants/field-types";
import { VoiceContext } from "../../../contexts/app-context";

import StringUtil from "../../../util/string.util";
import AppUtil from "../../../util/app.util";
import { VoicePrompt } from "../../../models/voice-file";

import SpeechInput from "../speech-input/SpeechInput";
import ReadbackInputData from "../readback-input-data/readback-input-data";

import ElementService from "../../../services/element.service";
import VariableService from "../../../services/variable.service";

/**
 * User input properties view in diagram shown as left panel
 * @param {Object} props Props data from parent component
 */
const UserInputPropertiesView = () => {
  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // information for invalid voice file 
  const [invalidVoiceFileInfo, setInvalidVoiceFileInfo] = useState({});
  // ui checkboxes state props
  const [uiCheckboxesState, setUiCheckboxesState] = useState({
    requirePrefixSymbolUserInput: false, requireSeparatorUserInput: false,
    requirePatternsUserInput: false, userInputReadBack: false, isRegexValidation: false
  });
  // state of variables with their info
  const [variableInfo, setVariableInfo] = useState({
    variables: [], variablesName: [], minValueSelectedVariable: '', minValueSelectedVariableDesc: '', maxValueSelectedVariable: '',
    maxValueSelectedVariableDesc: ''
  });

  const { element, tabType } = useContext(VoiceContext);

  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);
  const { languagesConfigured } = useSelector(state => state.client);
  /**
   * errMsg : msg to display incase of any invalid input
   * activeTab : to maintain state of of tab opened on new element
   */
  const [uiState, setUiState] = useState({
    errMsg: "", activeTab: "custom-audio",
    validationInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION)
  });
  // ref for openeing by default custom-audio tab
  const createAudioTabRef = useRef(null);
  // information for call end voice file
  const [callEndVoiceFileInfo, setCallEndVoiceFileInfo] = useState({});
  // information for call end voice file on no input
  // if no input is given then play the different voice file else if invalid input then play different file
  const [callEndOnNoInputVoiceFileInfo, setCallEndOnNoInputVoiceFileInfo] = useState({});
  // information for user input voice file
  const [userInputVoiceFileInfo, setUserInputVoiceFileInfo] = useState({});
  // state to store input state value to avoid laggy and choppyness and direct updating
  // direct updating in element is taking time , means lagging
  const [userInputInfo, setUserInputInfo] = useState({ variable: "", timeOut: "", precision: "", retryCount: "" });

  /**
   * value type for the min value and max value
   * { text: "Variable", value: "variable" }: User can define dynamic value for the min / max value by using 
   *                                          any variable specifying the value.
   * { text: "Value", value: "value" }: User can define some constant value for min / max value
   */
  const VALUE_TYPE_OPTIONS = [
    { text: "Variable", value: "variable" },
    { text: "Value", value: "value" }
  ]

  useEffect(() => {
    // if the user has allowed to accept input during readback of user-input
    const allowInputDuringReadback = element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_READBACK);
    if (!AppUtil.isValueValid(allowInputDuringReadback)) {
      // if not defined, then set it to true
      ElementService.updateElementAttr(element, ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, true);
    }
    // whether or not to end call after retries
    const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES);
    if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
      // if not defined, then set it to true
      ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, true);
    }
    // Whenever `element` changes, reset the active tab to 'custom-audio'
    setUiState({
      ...uiState, activeTab: "custom-audio",
      validationInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION)
    })
    // when open second element then it is not updating the tab , need to update the tab content also create-audio or user-input
    if (createAudioTabRef?.current) {
      createAudioTabRef.current.click();
    }
    // update the checkboxes on element changes
    setCheckboxesPropsInState({
      requirePrefixSymbolUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX, false),
      requireSeparatorUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR, false),
      requirePatternsUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS, false),
      userInputReadBack: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_READBACK, false),
      isRegexValidation: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION, false),
    });

    // populate the state with the variables list
    populateVariables();
    // get the call end and invalid voice file info
    getCallEndVoiceFileInfo();
    getInvalidVoiceFileInfo();
    getUserInputVoiceFileInfo();
    getCallEndOnNoInputVoiceFileInfo();
    // set the input states from the element
    const [variable, timeOut, precision, retryCount] = ElementService.getAttributes(element, [
      ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, ATTRIBUTES.USER_INPUT_PRECISION,
      ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT]);
    setUserInputInfo({ variable, timeOut, precision, retryCount });
  }, [element]);

  useEffect(() => {
    const init = async () => {
      const retryCount = ElementService.getAttribute(element,
        ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT) || DEFAULT_RETRY_COUNT;
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, retryCount);

      const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES);
      if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, true);
      }
      setSelectedLanguage(languagesConfigured?.length && languagesConfigured?.includes("en") ? "en" : languagesConfigured[0]);
    }
    init();
  }, []);

  /**
   * Get the call end voice file information from user input and set in the call voice file info
   */
  const getCallEndVoiceFileInfo = () => {
    // get the call en voice file info and parse it
    let callEndVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_CALL_END_VOICE_FILE);
    callEndVoiceFileInfo = JSON.parse(callEndVoiceFileInfo);
    const tmpVoiceFilePromptInfo = {};
    if (callEndVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        tmpVoiceFilePromptInfo[lang] = callEndVoiceFileInfo[lang];
      });
    }
    // set the call end voice file info state
    setCallEndVoiceFileInfo(tmpVoiceFilePromptInfo);
  }

  /**
   * Get the invalid voice file information from user input and set in the call voice file info
   */
  const getInvalidVoiceFileInfo = () => {
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
    const tmpInvalidVoiceFilePromptInfo = {};
    if (invalidVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        if (invalidVoiceFileInfo[lang]?.voiceFileType) {
          tmpInvalidVoiceFilePromptInfo[lang] = [new VoicePrompt(invalidVoiceFileInfo[lang])];
        } else {
          tmpInvalidVoiceFilePromptInfo[lang] = invalidVoiceFileInfo[lang];
        }
      });
    } else {
      languagesConfigured.forEach((lang) => {
        tmpInvalidVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: voiceFilePrefixObj[lang] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE, voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY })];
      });
    }
    setInvalidVoiceFileInfo(tmpInvalidVoiceFilePromptInfo);
  }

  /**
   * Get the user input voice file information from user input
   */
  const getUserInputVoiceFileInfo = () => {
    let userInputVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
    userInputVoiceFileInfo = JSON.parse(userInputVoiceFileInfo);
    const tmpUserInputVoiceFilePromptInfo = {};
    if (userInputVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        // if info is present simply then menas not prompt list add it to array and make it prompt list
        if (userInputVoiceFileInfo[lang]?.voiceFileType) {
          tmpUserInputVoiceFilePromptInfo[lang] = [new VoicePrompt(userInputVoiceFileInfo[lang])];
        } else {
          // if new language added and no info present then add default info to it
          tmpUserInputVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: "", voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY })];
        }
      });
    } else {
      languagesConfigured.forEach((lang) => {
        tmpUserInputVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: "", voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY })];
      });
    }
    setUserInputVoiceFileInfo(tmpUserInputVoiceFilePromptInfo);
  }

  /**
   * Get the call end voice file info for option selection node on no input
   */
  const getCallEndOnNoInputVoiceFileInfo = () => {
    let callEndOnNoInputVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_CALL_END_ON_NO_INPUT_VOICE_FILE);
    callEndOnNoInputVoiceFileInfo = JSON.parse(callEndOnNoInputVoiceFileInfo);
    const tmpCallEndOnNoInputPromptInfo = {};
    if (callEndOnNoInputVoiceFileInfo) {
      languagesConfigured.forEach((lang) => {
        tmpCallEndOnNoInputPromptInfo[lang] = callEndOnNoInputVoiceFileInfo[lang];
      });
    }
    setCallEndOnNoInputVoiceFileInfo(tmpCallEndOnNoInputPromptInfo);
  }

  // Get variables and set it in variables state.
  const populateVariables = async () => {
    const variablesInfo = await VariableService.getVariables();
    // names of all the variables
    const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

    // getting minimum value variable
    const minValueVariable = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, '');
    // getting minimum value variable details
    const minValueSelectedVariableDetails = getVariableDetails(variablesInfo, minValueVariable);
    // getting maximum value variable
    const maxValueVariable = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, '');
    // getting maximum value variable details
    const maxValueSelectedVariableDetails = getVariableDetails(variablesInfo, maxValueVariable);
    // populating the variables in state
    setVariableInfo({
      ...variableInfo, variables: variablesInfo, variablesName: allVariablesNames,
      minValueSelectedVariable: minValueVariable, maxValueSelectedVariable: maxValueVariable,
      minValueSelectedVariableDesc: minValueSelectedVariableDetails?.description,
      maxValueSelectedVariableDesc: maxValueSelectedVariableDetails?.description
    });
  }

  /**
   * Finds the details for the given variable from variable list
   * @param {array} variables variable list
   * @param {string} variableName variable name whose description is to be find
   * @returns {string} details for given variable 
   */
  const getVariableDetails = (variables = [], variableName = '') => {
    if (!variableName) return '';
    return variables?.find(variable => variable.name === variableName) || '';
  };

  /**
   * Updating the checkboxes state props in the state
   * @param {{ requirePrefixSymbolUserInput, requireSeparatorUserInput, 
   * requirePatternsUserInput, userInputReadBack,isRegexValidation }} uiStateProps ui state props
   */
  const setCheckboxesPropsInState = (uiStateProps = {
    requirePrefixSymbolUserInput: false,
    requireSeparatorUserInput: false, requirePatternsUserInput: false, userInputReadBack: false,
    isRegexValidation: false
  }) => {
    setUiCheckboxesState((prevState) => {
      const newState = { ...prevState };
      newState.requirePrefixSymbolUserInput = StringUtil.toBoolean(uiStateProps.requirePrefixSymbolUserInput);
      newState.requireSeparatorUserInput = StringUtil.toBoolean(uiStateProps.requireSeparatorUserInput);
      newState.requirePatternsUserInput = StringUtil.toBoolean(uiStateProps.requirePatternsUserInput);
      newState.userInputReadBack = StringUtil.toBoolean(uiStateProps.userInputReadBack);
      newState.isRegexValidation = StringUtil.toBoolean(uiStateProps.isRegexValidation);
      return newState;
    })

  }

  /**
     * Handles the selection of a variable from the Typeahead component. Updates the flow xml element with min and max values.
     * Only for selecting variable for min and max value for user input.
     * @param {Object} selected The selected option from the Typeahead component.
     * @param {string} field the field whose value is being changed (minValue / maxValue)
     */
  const handleVariableSelection = (selected, field = "") => {
    const selectedVariable = selected.length > 0 ? selected[0] : "";
    updateVariableInfo(selectedVariable, field);
  }

  /**
    * Updates the variable selected for min value and max value. Updates the flow xml element with min and max values.
    * @param {string} text The current text input in the Typeahead component
    * @param {Object} field  the field whose value is being changed (minValue / maxValue)
    */
  const updateVariableInfo = (text, field = "") => {
    const selectedVariable = text;
    const selectedVariableDetails = getVariableDetails(variableInfo.variables, selectedVariable);
    if (field === 'minValue') {
      // change is for the minimum value input (variable type)
      setVariableInfo({
        ...variableInfo, minValueSelectedVariable: selectedVariable,
        minValueSelectedVariableDesc: selectedVariableDetails?.description
      });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, selectedVariable);
    } else {
      // change is for the maximum value input (variable type)
      setVariableInfo({
        ...variableInfo, maxValueSelectedVariable: selectedVariable,
        maxValueSelectedVariableDesc: selectedVariableDetails?.description
      });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, selectedVariable);
    }
  }

  /**
   * Update the element based on attribute value
   * @param {Object} element 
   * @param {Object} event Object which contains the value of checkbox 
   * @param {string} attribute Attribute to update its value
   */
  const updateUserInputAttribute = (element, event, attribute) => {
    const isChecked = event.target.checked;
    ElementService.updateElement(element, attribute, isChecked);
    switch (attribute) {
      case ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION:
        if (!isChecked) {
          setUiState({ ...uiState, validationInput: "", errMsg: "" });
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION, "");
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS, "");
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack, isRegexValidation: isChecked
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS:
        //if Pattern is not needed, clean Pattern fields as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput, requirePatternsUserInput: isChecked,
          userInputReadBack: uiCheckboxesState.userInputReadBack, isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_VOICE_FILE:
        //if Voice File is not needed, clean userInputVoiceFile field as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SYMBOL_VOICE_FILE, "");
        }
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR:
        //if Separator is not needed, clean Separator fields as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: isChecked, requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack,
          isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX:
        //if Prefix Symbol is not needed, clean userInputPrefixSymbol field as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: isChecked,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack,
          isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_OPTION_READBACK:
        //if readback is not needed, clean digit count as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, 0);
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, "alpha");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: isChecked, isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      default:
        break;
    }
  }

  /**
   * Handles the validation of user input
   * @param {string} input value
   */
  const handleInputValidation = (input) => {
    setUiState({ ...uiState, validationInput: input });
    if (!input) {
      setUiState({ ...uiState, errMsg: "", validationInput: input });
      return;
    }
    // check if regex is valid
    if (AppUtil.isValidRegex(input)) {
      setUiState({ ...uiState, errMsg: "", validationInput: input });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION, input);
    } else {
      setUiState({
        ...uiState, errMsg: MESSAGES.ERR.INPUT_RANGE_VALIDATION,
        validationInput: input
      });
    }
  }

  /**
   * updates the prompt list and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateCallEndPromptList = (updatedPromptsList, language) => {
    const updatedCallEndVoiceFileInfo = { ...callEndVoiceFileInfo, [language]: updatedPromptsList };
    setCallEndVoiceFileInfo(updatedCallEndVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_CALL_END_VOICE_FILE, JSON.stringify(updatedCallEndVoiceFileInfo));
  }

  /**
   * updates the prompt list of invalid voice file and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateInvalidPromptList = (updatedPromptsList, language) => {
    const updatedInvalidVoiceFileInfo = { ...invalidVoiceFileInfo, [language]: updatedPromptsList };
    setInvalidVoiceFileInfo(updatedInvalidVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(updatedInvalidVoiceFileInfo));
  }

  /**
   * updates the prompt list of user input voice file and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateUserInputPromptList = (updatedPromptsList, language) => {
    let updatedUserInputVoiceFileInfo = {};
    languagesConfigured.forEach((lang) => {
      if (lang === language) {
        updatedUserInputVoiceFileInfo[lang] = updatedPromptsList[0];
      } else {
        updatedUserInputVoiceFileInfo[lang] = userInputVoiceFileInfo[lang][0];
      }
    });
    ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(updatedUserInputVoiceFileInfo));
    updatedUserInputVoiceFileInfo = { ...userInputVoiceFileInfo, [language]: updatedPromptsList };
    setUserInputVoiceFileInfo(updatedUserInputVoiceFileInfo);
  }

  /**
   * updates the prompt list and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdateCallEndOnNoInputPromptList = (updatedPromptsList, language) => {
    const updatedCallEndOnNoInputVoiceFileInfo = { ...callEndOnNoInputVoiceFileInfo, [language]: updatedPromptsList };
    setCallEndOnNoInputVoiceFileInfo(updatedCallEndOnNoInputVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_CALL_END_ON_NO_INPUT_VOICE_FILE, JSON.stringify(updatedCallEndOnNoInputVoiceFileInfo));
  }

  /**
   * Debounces the function to update the user input state attribute for an element.
   * This ensures that the update operation is only triggered after the user stops typing for 500ms,
   * Wrapping the debounce function in useMemo ensures that the updateInputStateAttr function is not recreated on every render 
   * unless its dependencies on element change. 
   * This is useful because creating a new debounced function each time would lose the existing debounce timeout
   */
  const updateInputStateAttr = useMemo(
    () =>
      debounce((attribute, value) => {
        ElementService.updateElement(element, attribute, value);
      }, 500),
    [element]
  );

  /**
   * Handles changes to the input text fields.
   * Updates the local state and triggers the debounced update function to persist the change.
   * @param {string} field - The field whose value is to be updated in local state
   * @param {string} attribute - The attribute whose state is to be updated in element
   * @param {string} value - The new value entered by the user.
   */
  const handleInputStateChange = (field, attribute, value) => {
    setUserInputInfo({ ...userInputInfo, [field]: value });
    updateInputStateAttr(attribute, value);
  }

  return (
    <>
      <VoiceContext.Provider
        value={{
          supportedLanguages: languagesConfigured, selectedLanguage: selectedLanguage, element: element,
        }}>
        {
          (tabType === TAB_LIST)
            ? <>
              <button className={`nav-link ${uiState.activeTab === 'custom-audio' ? 'active' : ''}`}
                id="custom-audio" data-bs-toggle="tab"
                data-bs-target="#edit-custom-audio" type="button" role="tab" aria-controls="nav-profile"
                aria-selected={uiState.activeTab === 'custom-audio'}
                onClick={(event) => setUiState({ ...uiState, activeTab: event.currentTarget?.id })}
                ref={createAudioTabRef}>Create audio</button>
              <button className={`nav-link ${uiState.activeTab === 'user-input' ? 'active' : ''}`} id="user-input" data-bs-toggle="tab" data-bs-target="#edit-user-input"
                type="button" role="tab" aria-controls="edit-user-input" aria-selected={uiState.activeTab === 'user-input'}
                onClick={(event) => setUiState({ ...uiState, activeTab: event.currentTarget?.id })}>Input</button>
            </>
            :
            <>
              <div className={`tab-pane fade ${uiState.activeTab === 'custom-audio' ? 'active show' : ''} mt-1`}
                id="edit-custom-audio" role="tabpanel" aria-labelledby="nav-home-tab">

                <VoicePromptAccordion title="User Input Voice File" voicePromptInfo={userInputVoiceFileInfo}
                  onSave={handleUpdateUserInputPromptList} showAddPromptBtn={false}
                  showVariableOption={false} />

                <VoicePromptAccordion title="Call End Voice File On Invalid Input" voicePromptInfo={callEndVoiceFileInfo} onSave={handleUpdateCallEndPromptList} />
                <VoicePromptAccordion title="Call End Voice File On No Input" voicePromptInfo={callEndOnNoInputVoiceFileInfo} onSave={handleUpdateCallEndOnNoInputPromptList} />
                {/* <Invalid option voice file with prompt list /> */}
                <VoicePromptAccordion title="Invalid File Option" voicePromptInfo={invalidVoiceFileInfo} onSave={handleUpdateInvalidPromptList} />
              </div>
              <div className={`tab-pane fade ${uiState.activeTab === 'user-input' ? 'active show' : ''}`}
                id="edit-user-input" role="tabpanel" aria-labelledby="nav-home-tab">
                <div className=" m-2 mt-3">
                  <PmivrCheckbox label={"End Call If Invalid User Input After Retries"}
                    value={element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES) || false}
                    info={TOOLTIP.INFO.END_CALL_IF_INVALID_INPUT_AFTER_RETRY}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, value);
                    }} />
                  <PmivrCheckbox label={"Allow Input During Readback"}
                    value={element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_READBACK) || false}
                    info={TOOLTIP.INFO.ALLOW_INPUT_DURING_READBACK}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, value);
                    }} />
                  <PmivrCheckbox label={"Submit Input With # Key"}
                    value={element.businessObject.get(ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED) || false}
                    info={TOOLTIP.INFO.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED}
                    disabled={element.businessObject.get(ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT) || false}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, value);
                    }} />
                  <PmivrCheckbox label={"Allow Empty Values As Input With # Key"}
                    value={element.businessObject.get(ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT) || false}
                    info={TOOLTIP.INFO.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT, value);
                      ElementService.updateElement(element, ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, value);
                    }} />
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Variable" tooltip={TOOLTIP.INFO.USER_INPUT_VARIABLE} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_VARIABLE}>
                      <input
                        id="inputVar"
                        name="inputVar"
                        className="form-control pmivr-input"
                        placeholder="Enter Variable"
                        value={userInputInfo?.variable}
                        onChange={(event) => {
                          handleInputStateChange('variable', ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Retry Count" tooltip={TOOLTIP.INFO.USER_INPUT_RETRY_COUNT} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_RETRY_COUNT}>
                      <input
                        id="inputVar" name="inputVar" type="number" className="form-control pmivr-input" placeholder="Enter Retry Count"
                        defaultValue={DEFAULT_RETRY_COUNT}
                        value={userInputInfo?.retryCount}
                        onChange={(event) => {
                          handleInputStateChange('retryCount', ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Timeout" tooltip={TOOLTIP.INFO.USER_INPUT_TIMEOUT} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_TIMEOUT}>
                      <input
                        id="inputVar" name="inputVar" type="number" className="form-control pmivr-input"
                        placeholder="Enter Timeout"
                        value={userInputInfo?.timeOut}
                        onChange={(event) => {
                          handleInputStateChange('timeOut', ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Decimal Precision" tooltip={TOOLTIP.INFO.USER_INPUT_PRECISION} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_PRECISION}>
                      <input
                        id="precision" name="precision" type="number" className="form-control pmivr-input" placeholder="Enter Value"
                        value={userInputInfo?.precision}
                        onChange={(event) => {
                          handleInputStateChange('precision', ATTRIBUTES.USER_INPUT_PRECISION, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>

                  <SpeechInput noInputTimeout={userInputInfo?.timeOut} />

                  <Accordion className="my-3 pmivr-accordion" flush>
                    <Accordion.Item eventKey={ACCORDION.USER_INPUT_EVENT_KEY} className="mt-3 accordion-voice-item">
                      <Accordion.Header>
                        <span className="pmivr-accordian-tab">
                          Readback
                        </span>
                      </Accordion.Header>
                      <Accordion.Body className="p-3 pt-0">
                        <div className="row">
                          <div className="col">
                            <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                              <input id="userInputReadBack" name="userInputReadBack" className="form-check-input  radio"
                                type="checkBox" checked={uiCheckboxesState.userInputReadBack || false}
                                onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_OPTION_READBACK)} />
                              <label className="form-check-label ">Readback of Input Data</label>
                              <PmivrTooltip message={TOOLTIP.INFO.IS_READBACK_INPUT}>
                                <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                              </PmivrTooltip>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_DATA_READBACK}>
                            <input id="userInputPromptLastDigitsCount" name="userInputPromptLastDigitsCount"
                              className="form-control pmivr-input" type="number"
                              value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, "")}
                              onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, event.target.value);
                              }}
                              disabled={!uiCheckboxesState.userInputReadBack} placeholder="Enter Digit Count To Prompt (from Last)" />
                          </PmivrOverlayTrigger>
                        </div>
                        <ReadbackInputData element={element} disabled={!uiCheckboxesState.userInputReadBack} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion className="my-3 pmivr-accordion" flush>
                    <Accordion.Item eventKey={ACCORDION.USER_INPUT_EVENT_KEY} className="mt-3 accordion-voice-item">
                      <Accordion.Header>
                        <span className="pmivr-accordian-tab">
                          Validations
                        </span>
                      </Accordion.Header>
                      <Accordion.Body className="p-3 pt-0">
                        <div className="row">
                          <div className="col">
                            <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                              <input
                                id="isRegexValidation"
                                name="isRegexValidation"
                                className="form-check-input  radio"
                                type="checkBox"
                                checked={uiCheckboxesState.isRegexValidation}
                                onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION)}
                              />
                              <label className="form-check-label ">Enable Validation Regex</label>
                              <PmivrTooltip message={TOOLTIP.INFO.IS_REGEX_VALIDATION}>
                                <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                              </PmivrTooltip>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <PmivrLabel label="Validation Regex" tooltip={TOOLTIP.INFO.USER_INPUT_REGEX_VALIDATION}
                            disabled={!uiCheckboxesState.isRegexValidation} />
                          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_REGEX_VALIDATION}>
                            <input id="regexValidation" name="regexValidation" className="form-control pmivr-input" type="text"
                              placeholder="Input validation regex" // this is required as it will use mentioned label as its value
                              value={uiState?.validationInput}
                              onChange={(event) => { handleInputValidation(event.target.value) }}
                              disabled={!uiCheckboxesState.isRegexValidation}
                            />
                          </PmivrOverlayTrigger>
                          {uiState?.errMsg && <div className="field-error">{uiState?.errMsg}</div>}
                        </div>
                        <div className="row">
                          <div className="col-md-6 form-group mb-3">
                            <PmivrLabel label="Min Digits" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_DIGITS} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MIN_DIGITS}>
                              <input id="minDigits" name="minDigits" className="form-control pmivr-input" type="number"
                                placeholder="Enter Min Digits"
                                value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS) || ""}
                                onChange={(event) => {
                                  ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS, event.target.value);
                                }}
                                disabled={uiCheckboxesState.isRegexValidation}
                              />
                            </PmivrOverlayTrigger>
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <PmivrLabel label="Max Digits" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_DIGITS} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MAX_DIGITS}>
                              <input
                                id="maxDigits" name="maxDigits" className="form-control pmivr-input" type="number" placeholder="Enter Max Digits"
                                value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS) || ""}
                                onChange={(event) => {
                                  ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS, event.target.value);
                                }}
                                disabled={uiCheckboxesState.isRegexValidation}
                              />
                            </PmivrOverlayTrigger>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Min Value Type" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_VALUE_TYPE} />
                            <select id="minValueInputType" name="minValueInputType" className="pmivr-select"
                              disabled={uiCheckboxesState.isRegexValidation}
                              value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE) || ""}
                              onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE, event.target.value);
                                // reset value of variable / value for minimum value of user input
                                updateVariableInfo('', 'minValue');
                              }}>
                              <option key={'Select Type'} value={''}>Select</option>
                              {
                                VALUE_TYPE_OPTIONS.map((type) => {
                                  return (
                                    <option key={type.value} value={type.value}>
                                      {type.text}
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Min Value" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_VALUE} />
                            {ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE) === "variable" ?
                              <PmivrOverlayTrigger tooltip={variableInfo.minValueSelectedVariableDesc}>
                                <Typeahead id="basic-typeahead-single" className='pmivr-variable-input' labelKey="selectedMinValueVariable"
                                  onChange={(selected) => handleVariableSelection(selected, 'minValue')}
                                  placeholder="Select Variable" multiple={false} options={variableInfo.variablesName}
                                  onInputChange={(text, event) => updateVariableInfo(text, 'minValue')}
                                  selected={variableInfo.minValueSelectedVariable ? [variableInfo.minValueSelectedVariable] : []} />
                              </PmivrOverlayTrigger>
                              : <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MIN_VALUE}>
                                <input id="minValue" name="minValue" className="form-control pmivr-input" placeholder="Enter Min Value" type="number"
                                  value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE) || ""}
                                  onChange={(event) => {
                                    ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, event.target.value);
                                  }}
                                  disabled={uiCheckboxesState.isRegexValidation
                                    || !AppUtil.isValueValid(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE))}
                                />
                              </PmivrOverlayTrigger>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Max Value Type" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_VALUE_TYPE} />
                            <select id="maxValueInputType" name="maxValueInputType" className="pmivr-select"
                              disabled={uiCheckboxesState.isRegexValidation}
                              value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE) || ""}
                              onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE, event.target.value);
                                // reset value of variable / value for minimum value of user input
                                updateVariableInfo('');
                              }}>
                              <option key={'Select Type'} value={''}>
                                Select
                              </option>
                              {
                                VALUE_TYPE_OPTIONS.map((type) => {
                                  return (
                                    <option key={type.value} value={type.value}>
                                      {type.text}
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Max Value" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_VALUE} />
                            {ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE) === "variable" ?
                              <PmivrOverlayTrigger tooltip={variableInfo.maxValueSelectedVariableDesc}>
                                <Typeahead id="basic-typeahead-single" className='pmivr-variable-input' labelKey="selectedMaxValueVariable"
                                  onChange={(selected) => handleVariableSelection(selected)} placeholder="Select Variable" multiple={false}
                                  // only show the simple variables in option
                                  options={variableInfo.variablesName} onInputChange={(text, event) => updateVariableInfo(text)}
                                  selected={variableInfo.maxValueSelectedVariable ? [variableInfo.maxValueSelectedVariable] : []} />
                              </PmivrOverlayTrigger>
                              : <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MAX_VALUE}>
                                <input id="maxValue" name="maxValue" className="form-control pmivr-input" placeholder="Enter Max Value" type="number"
                                  value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE) || ""}
                                  onChange={(event) => {
                                    ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, event.target.value);
                                  }}
                                  disabled={uiCheckboxesState.isRegexValidation
                                    || !AppUtil.isValueValid(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE))
                                  }
                                />
                              </PmivrOverlayTrigger>
                            }
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requirePatternsUserInput"
                          name="requirePatternsUserInput"
                          className="form-check-input radio"
                          type="checkBox"
                          checked={uiCheckboxesState.requirePatternsUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS)}
                        />
                        <label className="form-check-label ">AlphaNumeric Pattern?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_ALPHA_NUMERIC_PATTERN}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.ALPHANUMERIC_PATTERN}>
                      <input
                        id="userInputPattern"
                        name="userInputPattern"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, event.target.value);
                        }}
                        disabled={!uiCheckboxesState.requirePatternsUserInput}
                        placeholder="Enter Alphanumeric Pattern"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requireSeparatorUserInput"
                          name="requireSeparatorUserInput"
                          className="form-check-input  radio"
                          type="checkBox"
                          checked={uiCheckboxesState.requireSeparatorUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR)}
                        />
                        <label className="form-check-label ">Input Separator?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_INPUT_SEPERATOR}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_SEPARATOR}>
                      <input
                        id="userInputPatternSeparator"
                        name="userInputPatternSeparator"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, event.target.value);
                        }}
                        disabled={!uiCheckboxesState.requireSeparatorUserInput}
                        placeholder="Enter Separator"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requirePrefixSymbolUserInput"
                          name="requirePrefixSymbolUserInput"
                          className="form-check-input radio"
                          type="checkBox"
                          checked={uiCheckboxesState?.requirePrefixSymbolUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX)}
                        />
                        <label className="form-check-label">Input Prefix?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_INPUT_PREFIX}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_PREFIX}>
                      <input
                        id="userInputPrefixSymbol"
                        name="userInputPrefixSymbol"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, event.target.value);
                        }}
                        disabled={!uiCheckboxesState?.requirePrefixSymbolUserInput}
                        placeholder="Enter Prefix"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                </div>
              </div>
            </>
        }
      </VoiceContext.Provider>
    </>
  )
}

export default UserInputPropertiesView;